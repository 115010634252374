import { createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const globalRequest = createApi({
    reducerPath: 'globalRequest',
    baseQuery: fetchBaseQuery({baseUrl: 'http://16.170.31.159/api/'}),
    endpoints: (build) => ({
        postReviews: build.mutation({
            query: (body) => ({
                url: `review/create?from_user=${body.url.fullname}&organization_name=${body.url.company}&body=${body.url.body}`,
                method: "POST",
                body:body.formData,
            })
        }),
    }),
})
export const { usePostReviewsMutation,  } = globalRequest
export default globalRequest