import { Button } from '@mui/material'
import { Upload } from 'antd'
import React from 'react'
import { Player } from 'video-react'
import "../../../node_modules/video-react/dist/video-react.css";

function VideoUploader(props) {
    const {
        handleChangeVideo=()=>{},
        videoSrc,
    } = props
  return (
    <div className='upload_none_style_error w-full'>
        <Upload className="mt-3 mb-3"
            accept=".WebM, .MOV, .MP4, .FLV, .WMV, .OGG"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType=""
            maxCount={1}
            onChange={handleChangeVideo}>
            <Button variant='outlined' color='info'>
                Выберите видео
            </Button>
        </Upload>
        <br/>
        <div className='border'>
          <Player
            playsInline
            src={videoSrc}
            fluid={true}
            width='100%'
            height={272}
          />
        </div>
        <p className='text-[16px] font-[500] leading-[20px] mt-[10px]'>не более 200 mb</p>
    </div>
  )
}

export default VideoUploader