import React from 'react'

function Language() {
  return (
    <div className='w-full py-[20px]'>
        <div className='grid-cols-3 grid xl:gap-[42px] lg:gap-[40px] gap-[0px] w-full lg:text-[24px] text-[16px]'>
            <div className='md:pl-[10px] md:text-start text-center'>
                <h1>English</h1>
            </div>
            <div className='md:pl-[10px] md:text-start text-center'>
                <h1>Русский</h1>
            </div>
            <div className='md:pl-[10px] md:text-start text-center'>
                <h1>Тоҷикӣ</h1>
            </div>
        </div>
    </div>
  )
}

export default Language