import React from 'react'
import logo_b from '../../images/2 2.png'
import './Logo_style.css'

function Logo() {
  return (
    <div>
      <div class="content">
		    <h2>b</h2>
		    <h2>b</h2>
    	</div>
    </div>
  )
}

export default Logo