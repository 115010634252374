import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  axiosLogin,
  axiosRequest,
  saveToken,
  updateMessage,
} from "../../axiosRequest";
import axios from "axios";
import { message } from "antd";

export const getProjects = createAsyncThunk(
  "getProjects",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosLogin.get("/get_projects");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const loginUser = createAsyncThunk(
  "loginUser",
  async ({ body, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosLogin.post(`login`, body);
      saveToken(data.access_token, data.refresh_token);
      navigate("/admin");
      return data;
    } catch (error) {
      message.error("Invalid login or password");
      return rejectWithValue(error.message);
    }
  }
);

export const createProject = createAsyncThunk(
  "createProject",
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post("/post_project", body.body);
      if (body.fotoOrVideo == 1) {
        await dispatch(
          createImage({ formData: body.formData[0], item_id: data.item_id })
        );
        await dispatch(
          createImage({
            formData: body.formData[1],
            item_id: data.item_id,
            secondImage: true,
          })
        );
      } else if (body.fotoOrVideo == 2) {
        dispatch(
          createVideo({ formData: body.formData, item_id: data.item_id })
        );
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createVideo = createAsyncThunk(
  "createVideo",
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        `/upload_video?item_id=${body.item_id}${
          body.fileLink ? `&fileLink=${body.fileLink}` : ""
        }`,
        body.formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createImage = createAsyncThunk(
  "createImage",
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        `/upload_image?item_id=${body.item_id}${
          body.fileLink ? `&fileLink=${body.fileLink}` : ""
        }${body.secondImage ? "&is_second=true" : ""}`,
        body.formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteProject = createAsyncThunk(
  "deleteProject",
  async ({ id, image = null, video = null }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.delete(
        `/delete_project?item_id=${id}${image ? `&image=${image}` : ""}${
          video ? `&video=${video}` : ""
        }`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editProject = createAsyncThunk(
  "createProject",
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.put(
        `/edit_project?item_id=${body.item_id}`,
        body
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const sendMessage = createAsyncThunk(
  "sendMessage",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const id = body?.id ? body.id : "-929353864";
      console.log(id);
      const token = `6483605930:AAHNKQe7Qujyhp01sLXwo-jmQjWrQWfxKIo`;
      const url = `https://api.telegram.org/bot${token}/sendMessage`;
      const data = await axios.post(url, {
        chat_id: id,
        text: `Name: ${body.name};\nPhone: ${body.phone};\nMessage: ${body.message}.`,
      });
      console.log(data);
      setTimeout(() => {
        dispatch(setTelegramMessageModal(false));
      }, 3000);
    } catch (error) {
      if (error?.response?.status == 400) {
        let id = JSON.parse(error?.request?.response)?.parameters
          ?.migrate_to_chat_id;
        updateMessage({ ...body, id: `${id}` });
        // console.log(JSON.parse(error?.request?.response)?.parameters?.migrate_to_chat_id,'----------')
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getFon = createAsyncThunk(
  "getFon",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosLogin.get(`/get_fon`);
      return data;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const setFon = createAsyncThunk(
  "setFon",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        `/set_fon?fileName=${body.fileName}&type=${body.type}`,
        body.formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTitle = createAsyncThunk(
  "getTitle",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosLogin.get(`/get_title`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createTitle = createAsyncThunk(
  "createTitle",
  async ({ body, type }, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post(
        `/create_title/?${type}=true`,
        body
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDescription = createAsyncThunk(
  "getDescription",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosLogin.get("get_description");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setDescriptionText = createAsyncThunk(
  "setDescriptionText",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.put("set_description_text", body);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const setDescriptionExperience = createAsyncThunk(
  "setDescriptionExperience",
  async (
    {
      key = null,
      value = null,
      id = null,
      formData = null,
      fileLink = null,
      image = null,
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosRequest.put(
        `set_description_experienec/${
          fileLink ? `?fileLink=${fileLink}` : "?"
        }${key ? `item_key=${key}` : ""}${
          value ? `&item_value=${value}` : ""
        }&item_id=${id}${image ? `&image=true` : ""}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteExperience = createAsyncThunk(
  "deleteExperience",
  async ({ id, fileName }, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.delete(
        `delete_experience/?item_id=${id}&file_name=${fileName}`
      );
    } catch (error) {
      rejectWithValue(error.value);
    }
  }
);

export const editExperienceText = createAsyncThunk(
  "editExperienceText",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.put(`set_experience_text`, body);
    } catch (error) {
      rejectWithValue(error.value);
    }
  }
);

export const getResume = createAsyncThunk(
  "getResume",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosLogin.get(`get_resume`);
      return data;
    } catch (error) {
      rejectWithValue(error.value);
    }
  }
);

export const createResume = createAsyncThunk(
  "createResume",
  async (
    { body, experience_or_education = null, skill_or_goals = null },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosRequest.post(
        `/create_resume/${
          experience_or_education
            ? `?experience_or_education=${experience_or_education}`
            : skill_or_goals
            ? `?skill_or_goals=${skill_or_goals}`
            : ""
        }`,
        body
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editResume = createAsyncThunk(
  "createResume",
  async (
    { body, experience_or_education = null, skill_or_goals = null },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosRequest.put(
        `/set_resume/${
          experience_or_education
            ? `?experience_or_education=${experience_or_education}`
            : skill_or_goals
            ? `?skill_or_goals=${skill_or_goals}`
            : ""
        }`,
        body
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleteResume = createAsyncThunk(
  "deleteResume",
  async (
    { experience_or_education = null, skill_or_goals = null, id },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axiosRequest.delete(
        `/delete_resume/${
          experience_or_education
            ? `?experience_or_education=${experience_or_education}`
            : skill_or_goals
            ? `?skill_or_goals=${skill_or_goals}`
            : ""
        }&item_id=${id}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getContact = createAsyncThunk(
  "getContact",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosLogin.get("/get_contact");
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const setContact = createAsyncThunk(
  "getContact",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.put("/set_contact", body);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const globalState = createSlice({
  name: "globalState",
  initialState: {
    loadings: false,
    refresh: null,
    telegramMessageModal: false,
    // modals
    deleteFlipTextModal: { open: false, id: null },
    openFileError: { open: false, name: "" },
    editExperienceIconModal: { open: false, id: null, fileLink: null },
    deleteExperienceModal: { open: false, id: null, fileName: null },
    editExperienceTextModal: { open: false, id: null, item: null },
    editFileModal: { open: false, type: null, id: null },
    editProjectModal: { open: false, item: null },
    cardModal: { open: false, item: null },
    deleteModal: { open: false, id: null },
    deleteResumeModal: { open: false, id: null, experience_or_education: null },
    deleteResumeModal2: { open: false, id: null, skill_or_goals: null },
    // data
    fonMainPage: {},
    titleData: [],
    descriptionData: {
      item_place: { ru: "", en: "", tj: "" },
      item_description: { ru: "", en: "", tj: "" },
    },
    resumeData: {},
    projectsData: [],
    contactData: {},
  },
  reducers: {
    setCardModal: (state, action) => {
      state.cardModal = action.payload;
    },
    setDeleteModal: (state, action) => {
      state.deleteModal = action.payload;
    },
    setRefresh: (state, action) => {
      state.refresh = Math.random();
    },
    setTelegramMessageModal: (state, action) => {
      state.telegramMessageModal = action.payload;
    },
    setEditProjectModal: (state, action) => {
      state.editProjectModal = action.payload;
    },
    setEditFileModal: (state, action) => {
      state.editFileModal = action.payload;
    },
    setDeleteFlipTextModal: (state, action) => {
      state.deleteFlipTextModal = action.payload;
    },
    setOpenFileError: (state, action) => {
      state.openFileError = action.payload;
    },
    setEditExperienceIconModal: (state, action) => {
      state.editExperienceIconModal = action.payload;
    },
    setDeleteExperienceModal: (state, action) => {
      state.deleteExperienceModal = action.payload;
    },
    setEditExperienceTextModal: (state, action) => {
      state.editExperienceTextModal = action.payload;
    },
    setDeleteResumeModal: (state, action) => {
      state.deleteResumeModal = action.payload;
    },
    setDeleteResumeModal2: (state, action) => {
      state.deleteResumeModal2 = action.payload;
    },
  },
  extraReducers: {
    [getProjects.pending]: (state, action) => {
      state.loadings = true;
    },
    [getProjects.fulfilled]: (state, action) => {
      state.projectsData = action.payload;
      state.loadings = false;
    },
    [getProjects.rejected]: (state, action) => {
      state.loadings = false;
    },
    [loginUser.pending]: (state, action) => {
      state.loadings = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.loadings = false;
    },
    [loginUser.rejected]: (state, action) => {
      state.loadings = false;
    },
    [createProject.pending]: (state, action) => {
      state.loadings = true;
    },
    [createProject.fulfilled]: (state, action) => {
      // state.loadings = false
    },
    [createProject.rejected]: (state, action) => {
      state.loadings = false;
    },
    [createVideo.pending]: (state, action) => {
      state.loadings = true;
    },
    [createVideo.fulfilled]: (state, action) => {
      state.loadings = false;
      state.refresh = Math.random();
      state.editFileModal = { open: false, type: null, id: null };
    },
    [createVideo.rejected]: (state, action) => {
      state.loadings = false;
    },
    [createImage.pending]: (state, action) => {
      state.loadings = true;
    },
    [createImage.fulfilled]: (state, action) => {
      state.loadings = false;
      state.refresh = Math.random();
      state.editFileModal = { open: false, type: null, id: null };
    },
    [createImage.rejected]: (state, action) => {
      state.loadings = false;
    },
    [deleteProject.pending]: (state, action) => {
      state.loadings = true;
    },
    [deleteProject.fulfilled]: (state, action) => {
      state.loadings = false;
      state.refresh = Math.random();
      state.deleteModal = { open: false, id: null };
    },
    [deleteProject.rejected]: (state, action) => {
      state.loadings = false;
    },
    [editProject.pending]: (state, action) => {
      state.loadings = true;
    },
    [editProject.fulfilled]: (state, action) => {
      state.loadings = false;
      state.refresh = Math.random();
      state.editProjectModal = { open: false, item: null };
    },
    [editProject.rejected]: (state, action) => {
      state.loadings = false;
    },
    [sendMessage.pending]: (state, aciton) => {
      state.loadings = true;
    },
    [sendMessage.fulfilled]: (state, action) => {
      state.loadings = false;
      state.telegramMessageModal = true;
    },
    [sendMessage.rejected]: (state, action) => {
      state.loadings = false;
    },
    [getFon.pending]: (state, action) => {
      state.loadings = true;
    },
    [getFon.fulfilled]: (state, action) => {
      state.loadings = false;
      state.fonMainPage = action.payload;
    },
    [getFon.rejected]: (state, action) => {
      state.loadings = false;
    },
    [setFon.pending]: (state, action) => {
      state.loadings = true;
    },
    [setFon.fulfilled]: (state, action) => {
      state.loadings = false;
      state.fonMainPage = action.payload;
    },
    [setFon.rejected]: (state, action) => {
      state.loadings = false;
    },
    [getTitle.pending]: (state, action) => {
      state.loadings = true;
    },
    [getTitle.fulfilled]: (state, action) => {
      state.loadings = false;
      state.titleData = action.payload;
    },
    [getTitle.rejected]: (state, action) => {
      state.loadings = false;
    },
    [createTitle.pending]: (state, action) => {
      state.loadings = true;
    },
    [createTitle.fulfilled]: (state, action) => {
      state.loadings = false;
      state.refresh = Math.random();
      state.deleteFlipTextModal = { open: false, id: null };
    },
    [createTitle.rejected]: (state, action) => {
      state.loadings = false;
    },
    [getDescription.pending]: (state, action) => {
      state.loadings = true;
    },
    [getDescription.fulfilled]: (state, action) => {
      state.loadings = false;
      state.descriptionData = action.payload;
    },
    [getDescription.rejected]: (state, action) => {
      state.loadings = false;
    },
    [setDescriptionText.pending]: (state, action) => {
      state.loadings = true;
    },
    [setDescriptionText.fulfilled]: (state, action) => {
      state.loadings = false;
      state.refresh = Math.random();
    },
    [setDescriptionText.rejected]: (state, action) => {
      state.loadings = false;
    },
    [setDescriptionExperience.pending]: (state, action) => {
      state.loadings = true;
    },
    [setDescriptionExperience.fulfilled]: (state, action) => {
      state.loadings = false;
      state.editExperienceIconModal = { open: false, id: null, fileLink: null };
      state.refresh = Math.random();
    },
    [setDescriptionExperience.rejected]: (state, action) => {
      state.loadings = false;
    },
    [deleteExperience.pending]: (state, action) => {
      state.loadings = true;
    },
    [deleteExperience.fulfilled]: (state, action) => {
      state.loadings = false;
      state.deleteExperienceModal = { open: false, id: null, fileName: null };
      state.refresh = Math.random();
    },
    [deleteExperience.rejected]: (state, action) => {
      state.loadings = false;
    },
    [editExperienceText.pending]: (state, action) => {
      state.loadings = true;
    },
    [editExperienceText.fulfilled]: (state, action) => {
      state.loadings = false;
      state.editExperienceTextModal = { open: false, id: null, item: null };
      state.refresh = Math.random();
    },
    [editExperienceText.rejected]: (state, action) => {
      state.loadings = false;
    },
    [getResume.pending]: (state, action) => {
      state.loadings = true;
    },
    [getResume.fulfilled]: (state, action) => {
      state.loadings = false;
      state.resumeData = action.payload;
    },
    [getResume.rejected]: (state, action) => {
      state.loadings = false;
    },
    [createResume.pending]: (state, action) => {
      state.loadings = true;
    },
    [createResume.fulfilled]: (state, action) => {
      state.loadings = false;
      state.refresh = Math.random();
    },
    [createResume.rejected]: (state, action) => {
      state.loadings = false;
    },
    [editResume.pending]: (state, action) => {
      state.loadings = true;
    },
    [editResume.fulfilled]: (state, action) => {
      state.loadings = false;
      state.editExperienceTextModal = { open: false, id: null, item: null };
      state.refresh = Math.random();
    },
    [editResume.rejected]: (state, action) => {
      state.loadings = false;
    },
    [deleteResume.pending]: (state, action) => {
      state.loadings = true;
    },
    [deleteResume.fulfilled]: (state, action) => {
      state.loadings = false;
      state.deleteResumeModal = {
        open: false,
        id: null,
        experience_or_education: null,
      };
      state.deleteResumeModal2 = {
        open: false,
        id: null,
        skill_or_goals: null,
      };
      state.refresh = Math.random();
    },
    [deleteResume.rejected]: (state, action) => {
      state.loadings = false;
    },
    [getContact.pending]: (state, action) => {
      state.loadings = true;
    },
    [getContact.fulfilled]: (state, action) => {
      state.loadings = false;
      state.contactData = action.payload;
    },
    [getContact.rejected]: (state, action) => {
      state.loadings = false;
    },
    [setContact.pending]: (state, action) => {
      state.loadings = true;
    },
    [setContact.fulfilled]: (state, action) => {
      state.loadings = false;
      state.contactData = action.payload;
    },
    [setContact.rejected]: (state, action) => {
      state.loadings = false;
    },
  },
});
export const {
  setCardModal,
  setDeleteModal,
  setRefresh,
  setTelegramMessageModal,
  setEditProjectModal,
  setEditFileModal,
  setDeleteFlipTextModal,
  setOpenFileError,
  setEditExperienceIconModal,
  setDeleteExperienceModal,
  setEditExperienceTextModal,
  setDeleteResumeModal,
  setDeleteResumeModal2,
} = globalState.actions;
export default globalState.reducer;
