import axios from "axios";
import jwt_decode from "jwt-decode";
import { sendMessage } from "./redux/reducers/GlobalState";
import { dis } from "./pages/layout/Layout";

const axiosLogin = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export const imgUrl = process.env.REACT_APP_API_URL;

const axiosRequest = axios.create({ 
    withCredentials: false,
    baseURL: process.env.REACT_APP_API_URL,
});
axiosRequest.interceptors.request.use(
    (config) => {
        const access_token = sessionStorage.getItem("access_token");
        if (access_token) {
            config.headers.authorization = `Bearer ${access_token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axiosRequest.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const {
            config,
            response: {status},
        } = error;
        const originalRequest = config;
        if (status === 401) {
            const refreshToken = sessionStorage.getItem("refresh_token");
            if (!refreshToken) {
                destroyToken();
                return Promise.reject(error);
            }
            try {
                const {data} = await axiosLogin.post(
                    `refresh_token?token=${refreshToken}`,
                );
                sessionStorage.setItem("access_token", data.access_token);
                originalRequest.headers.authorization = `Bearer ${data}`;
                return axios(originalRequest);
            } catch (err) {
                destroyToken();
                return Promise.reject(err);
            }
        }
        destroyToken();
        return Promise.reject(error);
    }
);

function saveToken(access_token, refresh_token) {
    sessionStorage.setItem("access_token", access_token);
    sessionStorage.setItem("refresh_token", refresh_token);
}

function getToken() {
    try {
        return jwt_decode(sessionStorage.getItem("access_token"));
    } catch (error) {
    }
}

function destroyToken() {
    sessionStorage.removeItem("access_token");
}

function updateMessage(body){
    console.log(body)
    dis(sendMessage(body))
}

export {axiosLogin, axiosRequest, saveToken, getToken, destroyToken, updateMessage};