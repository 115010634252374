import { Modal, Upload } from 'antd'
import React from 'react'
import { uploadButton } from '../../arrays'

function FileReaderotoUploader(props) {
    const {
        fileList,
        previewOpen,
        previewImage,
        previewTitle,
        handlePreview=()=>{},
        handleChangeFoto=()=>{},
        handleCancel=()=>{},
        quentityImages = 1
    } = props
  return (
    <div>
        <div className={`${fileList.length > 0 ? '' : ''} `}>
            <Upload
              accept='.JPEG, .PNG, .ICO, .GIF, .TIFF, .WebP, .EPS, .SVG, .PSD, .INDD, .CDR, .AI, .RAW, .JPG'
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChangeFoto}
              // multiple={true}
            >
              {fileList.length >= quentityImages ? null : uploadButton}
            </Upload>
        </div>
        <p className='text-[16px] font-[500] leading-[20px]'>не более 10 mb</p>
        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
          <img
            alt="example"
            style={{
              width: '100%',
            }}
            className='bg-gray-300'
            src={previewImage}
          />
        </Modal>
    </div>
  )
}

export default FileReaderotoUploader