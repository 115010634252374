import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../components/logo/Logo'
import home_icon_white from '../../images/3.png'
import home_icon_green from '../../images/3(1).png'
import about_icon_white from '../../images/5.png'
import about_icon_green from '../../images/5(1).png'
import resume_icon_white from '../../images/2.png'
import resume_icon_green from '../../images/2(1).png'
import portfolio_icon_white from '../../images/1.png'
import portfolio_icon_green from '../../images/1(1).png'
import contact_icon_white from '../../images/4.png'
import contact_icon_green from '../../images/4(1).png'
import ButtonLayout from '../../components/buttons/Buttons'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import { useDispatch, useSelector } from 'react-redux'
import { setLanguage } from '../../redux/reducers/LocallhostPState'
import axios from 'axios'
import { setCardModal, setTelegramMessageModal } from '../../redux/reducers/GlobalState'
import ReactPlayer from 'react-player'
import Loading from '../../components/loading/Loading'
import CardModal, { TelegramMessageModal } from '../../components/modals/Modal'
export let dis = ()=>{}

function Layout() {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [headerState, setHeaderState] = useState(true)
  const [languageButtons, setLanguageButtons] = useState(false);
  const [languageBtn,setLanguageBtn] = useState(false)
  const {pathname} = useLocation()
  const language = useSelector(({locallhostState})=>locallhostState.language)
  const cardModal = useSelector(({globalState})=>globalState.cardModal)
  const deleteModal = useSelector(({globalState})=>globalState.deleteModal)
  const loadings = useSelector(({globalState})=>globalState.loadings)
  const telegramMessageModal = useSelector(({globalState})=>globalState.telegramMessageModal)
  const page = useSelector(({locallhostState})=>locallhostState.page)
  const buttons = [
    {
      id:1,
      text:t('Home.layout'),
      img_white:home_icon_white,
      img_green:home_icon_green,
      href:'main',
    },
    {
      id:2,
      text:t('About_me.layout'),
      img_white:about_icon_white,
      img_green:about_icon_green,
      href:'about',
    },
    {
      id:3,
      text:t('Resume.layout'),
      img_white:resume_icon_white,
      img_green:resume_icon_green,
      href:'resume',
    },
    {
      id:4,
      text:t('Portfolio.layout'),
      img_white:portfolio_icon_white,
      img_green:portfolio_icon_green,
      href:'portfolio',
    },
    {
      id:5,
      text:t('Contacts.layout'),
      img_white:contact_icon_white,
      img_green:contact_icon_green,
      href:'contacts',
    },
  ]

  if(pathname =='/#main'){
    document.location.href = '#main'
  }else if(pathname =='/#about'){
    document.location.href = '#about'
  }else if(pathname =='/#resume'){
    document.location.href = '#resume'
  }else if(pathname =='/#portfolio'){
    document.location.href = '#portfolio'
  }else if(pathname =='/#contacts'){
    document.location.href = '#contacts'
  }

  const changeLanguage = (lan) => {
    i18n?.changeLanguage(lan)
    dispatch(setLanguage(lan))
  }

  useEffect(()=>{
    dis = dispatch
  },[])

  useEffect(()=>{
    if(language != t('language')){
      changeLanguage(language)
    }
  },[t])

  useEffect(()=>{
    if(pathname.toLowerCase() == '/login' || window.innerWidth <= 940){
      setHeaderState(false)
    }else{
      setHeaderState(true)
    }
  },[pathname])

  useEffect(()=>{
    if(cardModal.open || deleteModal.open || loadings || telegramMessageModal){
      document.body.setAttribute('style','overflow:hidden;');
    }else{
      document.body.setAttribute('style','overflow:visible;');
    }
  },[cardModal, deleteModal, loadings, telegramMessageModal])

  return (
    <div className='section bg-[#151617]'> 
      {
        headerState ? (
            <header onClick={()=>{if(window.innerWidth < 1200){setHeaderState(false)}}} className={`${pathname.toLowerCase() == '/login'  ? 'hidden' : ''} fixed top-0 left-0 xl:w-[237px] md:w-[130px] w-[77px] h-screen overflow-y-auto overflow-x-hidden bg-[rgba(14,15,16,0.7)] headerAnimation z-[999]`}>
              <div className='w-full flex flex-col min-h-[600px] relative'>
                <div className='absolute top-0 left-0 xl:w-[237px] md:w-[130px] w-[80px] flex justify-center headerAnimation'>
                  <div className='logoHeight'>
                    <Logo/>
                  </div>
                </div>
                <div className='grid grid-cols-1 buttonsHeight'>
                  {
                    buttons.map((elem,index)=>{
                      return (
                        <ButtonLayout 
                          page={page}
                          id={elem.id}
                          key={elem.id}
                          text={elem.text} 
                          href={elem.href}
                          dispatch={dispatch}
                          active={elem.active} 
                          img_white={elem.img_white} 
                          img_green={elem.img_green}
                          navigate={navigate}
                          onClick={()=>{if(window.innerWidth<1200){setHeaderState(false)}}}
                        />
                      )
                    })
                  }
                </div>
              </div>
            </header>
        ) : (
            <div className={`${pathname.toLowerCase() == '/login' ? 'hidden' : ''} fixed top-0 left-0 md:pt-[36px] pt-[20px] md:pl-[40px] pl-[20px] z-[999]`}>
                <button onClick={()=>setHeaderState(true)} className='flex flex-col md:gap-[6px] gap-[3.75px]'>
                    <div className='md:w-[40px] md:h-[3px] w-[25px] h-[1.88px] rounded-[5px] bg-[#C4C4C4]'></div>
                    <div className='md:w-[40px] md:h-[3px] w-[25px] h-[1.88px] rounded-[5px] bg-[#C4C4C4]'></div>
                    <div className='md:w-[40px] md:h-[3px] w-[25px] h-[1.88px] rounded-[5px] bg-[#C4C4C4]'></div>
                </button>
            </div>
        )
      }
      {/* <div className='fixed right-[10px] bottom-[15px] text-[#2BBB67] text-[18px] leading-[22px] font-[600] w-[44px] h-auto flex lhidden flex-col justify-between z-999'>
        {
            languageBtn ? 
            <>
                <button onClick={()=>{setLanguageButtons(!languageButtons); changeLanguage('tj');}} className={`${languageButtons ? 'languageBtn3' : 'languageBtn3Close'} w-[44px] h-[44px] my-[8px] rounded-full bg-[rgba(80,80,80,0.7)] shadow-[-1px_4px_6px] shadow-[rgba(154,154,154,0.25)] hover:shadow-[rgba(154,154,154,0.75)]`}>TJ</button>
                <button onClick={()=>{setLanguageButtons(!languageButtons); changeLanguage('ru');}} className={`${languageButtons ? 'languageBtn2' : 'languageBtn2Close'} w-[44px] h-[44px] my-[8px] rounded-full bg-[rgba(80,80,80,0.7)] shadow-[-1px_4px_6px] shadow-[rgba(154,154,154,0.25)] hover:shadow-[rgba(154,154,154,0.75)]`}>RU</button>
                <button onClick={()=>{setLanguageButtons(!languageButtons); changeLanguage('en');}} className={`${languageButtons ? 'languageBtn1' : 'languageBtn1Close'} w-[44px] h-[44px] my-[8px] rounded-full bg-[rgba(80,80,80,0.7)] shadow-[-1px_4px_6px] shadow-[rgba(154,154,154,0.25)] hover:shadow-[rgba(154,154,154,0.75)]`}>EN</button>
            </>
            : null
        }
        <button onClick={()=>{setLanguageButtons(!languageButtons); setLanguageBtn(true);}} className='z-10 w-[44px] h-[44px] my-[8px] rounded-full bg-[rgba(80,80,80,0.7)] shadow-[-1px_4px_6px] shadow-[rgba(154,154,154,0.25)] hover:shadow-[rgba(154,154,154,0.75)]'>
            {
                languageButtons ? <Close/> 
                : 
                t('language') == 'ru' ? 'RU': t('language') == 'en' ? "EN" : 'TJ'
            }
        </button>
      </div> */}
      <div className={`fixed z-[9999] lg:bottom-[19px] bottom-[39px] text-white font-[600] ${headerState ? 'xl:left-[237px] md:left-[130px] left-[77px]' : 'left-[15px]'} h-auto flex lhidden flex-col justify-between`}>
          <div className='lg:px-[19px] px-[9px] gap-[20px] flex font-[400] lg:text-[18px] md:text-[16px] text-[14px] lg:leading-[27px] md:leading-[24px] leading-[21px]'>
            <h3 onClick={()=>changeLanguage('en')} className={`${t('language') == 'en' ? 'border-b-[3px] border-[#00AA5D]' : ''} cursor-pointer`}>ENG</h3>
            <h3 onClick={()=>changeLanguage('ru')} className={`${t('language') == 'ru' ? 'border-b-[3px] border-[#00AA5D]' : ''} cursor-pointer`}>РУС</h3>
            <h3 onClick={()=>changeLanguage('tj')} className={`${t('language') == 'tj' ? 'border-b-[3px] border-[#00AA5D]' : ''} cursor-pointer`}>ТОҶ</h3>
          </div>
      </div>
      <Outlet/>
      <footer></footer>
      <CardModal open={cardModal.open} close={()=>dispatch(setCardModal({open:false,item:null}))} cardModal={cardModal}/>
      <TelegramMessageModal open={telegramMessageModal} close={()=>dispatch(setTelegramMessageModal(false))}/>
        { loadings ? <Loading/> : null}
    </div>
  )
}

export default Layout