import React from 'react'
import FotoUploader from '../fotoUploader/FotoUploader'
import VideoUploader from '../videoUploader/VideoUploader'

function FotoVideo(props) {
    const {
        fotoOrVideo,
        fileList,
        handlePreview=()=>{},
        handleChangeFoto=()=>{},
        previewOpen,
        previewTitle,
        handleCancel=()=>{},
        previewImage,
        handleChangeVideo=()=>{},
        videoSrc,
        quentityImages=1
    } = props
  return (
    <div>
        {
            fotoOrVideo == 1 ? (
                <FotoUploader
                    fileList={fileList}
                    previewOpen={previewOpen}
                    previewTitle={previewTitle}  
                    handleCancel={handleCancel}
                    previewImage={previewImage}  
                    handlePreview={handlePreview}
                    handleChangeFoto={handleChangeFoto}
                    quentityImages={quentityImages}
                />
            ) : (
                <VideoUploader handleChangeVideo={handleChangeVideo} videoSrc={videoSrc}/>
              )
            }
    </div>
  )
}

export default FotoVideo