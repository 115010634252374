import React from 'react'
import './Loading.css'

function Loading() {
  return (
    <div className='z-[99999999]'>
        <div className='fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] grid place-content-center'>
            <div class="spinner">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
  )
}

export default Loading