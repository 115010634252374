import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import { setMethodState, setPage } from "../../redux/reducers/LocallhostPState";
import { TfiSave } from "react-icons/tfi";
import { LuFileEdit } from "react-icons/lu";
import { MdDeleteOutline } from "react-icons/md";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { sections } from "../../arrays";
import { useTranslation } from "react-i18next";

function ButtonLayout(props) {
  const {
    id,
    text,
    href,
    page,
    img_white,
    img_green,
    dispatch,
    onClick = () => {},
  } = props;

  const { pathname } = useLocation();

  return (
    <div className="">
      <Link
        activeClass={pathname.toLowerCase() == "/admin" ? "" : "active"}
        to={href}
        onClick={() => {
          if (pathname.toLowerCase() == "/admin") {
            dispatch(setPage(id));
          }
          onClick();
        }}
        smooth
        spy
        className="flex flex-col items-center buttonLayout cursor-pointer"
      >
        <div className="md:w-[40px] md:h-[41.11px] w-[25px] h-[25.32px] flex ">
          <img
            src={img_white}
            alt="img_white"
            className={`${
              pathname.toLowerCase() == "/admin"
                ? page == id
                  ? "hidden"
                  : ""
                : "buttonLayoutHoverIconHide"
            } md:w-[40px] md:h-[41.11px] w-[25px] h-[25.32px] `}
          />
          <img
            src={img_green}
            alt="img_green"
            className={`${
              pathname.toLowerCase() == "/admin"
                ? page == id
                  ? "md:ml-[0px] ml-[0px]"
                  : "hidden"
                : "buttonLayoutHoverIconShow"
            } md:w-[40px] md:h-[41.11px] w-[25px] h-[25.32px] md:ml-[-40px] ml-[-25px] `}
          />
        </div>
        <div className="bg-[rgba(0,0,0,0.7)] md:w-[100px] md:h-[30px] w-[70px] h-[21px] rounded-[5px] md:text-[12px] text-[10px] font-[600] md:leading-[18px] leading-[15px] flex justify-center items-center uppercase text-[#2BBB67] buttonLayoutHoverText">
          {text}
        </div>
      </Link>
    </div>
  );
}

export const ButtonPortfolio = ({
  active,
  text,
  onActive = () => {},
  pathname,
  key,
}) => {
  return (
    <div key={key} className="flex flex-col ">
      <button
        type="button"
        onClick={onActive}
        className={`${active ? "" : ""} ${
          pathname == "/admin" ? "font-[400]" : "font-[500]"
        } ml md:leading-[35px] ${
          pathname == "/admin" ? "md:text-[18px]" : "md:text-[24px]"
        }`}
      >
        {text}
      </button>
      <div
        className={`w-full rounded-[5px] transition duration-300 h-[3px] ${
          active ? "bg-[#00AA5D]" : "bg-none"
        }`}
      ></div>
    </div>
  );
};

export const MethodButtons = (props) => {
  const methodState = useSelector(
    ({ locallhostState }) => locallhostState.methodState
  );
  const dispatch = useDispatch();
  const {
    page,
    section,
    post_disabled = false,
    edit_disabled = false,
    delete_disabled = false,
  } = props;
  return (
    <div className="flex justify-between items-center pb-[20px]">
      <h1 className="lg:text-[24px] lg:leading-[28px] text-[18px] leading-[24px] xl:pl-[73px] lg:pl-[17px] pl-[19px] text-white">
        {sections[page][section]}
      </h1>
      <div className="flex gap-[10px] items-center">
        <div
          className={`${
            methodState[page][section] == 1
              ? "border-[#00AA5D]"
              : "border-[#151617]"
          } border rounded-[50%] ${
            post_disabled ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          <IconButton
            onClick={() => {
              dispatch(
                setMethodState({
                  ...methodState,
                  [page]: { ...methodState[page], [section]: 1 },
                })
              );
            }}
            disabled={post_disabled}
            color="success"
          >
            <TfiSave className="text-[#00AA5D] lg:w-[25px] lg:h-[25px] w-[20px] h-[20px]" />
          </IconButton>
        </div>
        <div
          className={`${
            methodState[page][section] == 2
              ? "border-orange-500"
              : "border-[#151617]"
          } border rounded-[50%] ${
            edit_disabled ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          <IconButton
            onClick={() => {
              dispatch(
                setMethodState({
                  ...methodState,
                  [page]: { ...methodState[page], [section]: 2 },
                })
              );
            }}
            disabled={edit_disabled}
            color="warning"
          >
            <LuFileEdit className="text-orange-500 lg:w-[25px] lg:h-[25px] w-[20px] h-[20px]" />
          </IconButton>
        </div>
        <div
          className={`${
            methodState[page][section] == 3
              ? "border-red-500"
              : "border-[#151617]"
          } border rounded-[50%] ${
            delete_disabled ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          <IconButton
            onClick={() => {
              dispatch(
                setMethodState({
                  ...methodState,
                  [page]: { ...methodState[page], [section]: 3 },
                })
              );
            }}
            disabled={delete_disabled}
            color="error"
          >
            <MdDeleteOutline className="text-red-500 lg:w-[25px] lg:h-[25px] w-[20px] h-[20px]" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export const ButtonSubmit = ({ active = true }) => {
  const { t } = useTranslation();
  return (
    <>
      {active ? (
        <button
          type="submit"
          className={`bg-[#15271C] text-[#B3DBC8] lg:px-[18px] px-[12px] lg:py-[11px] py-[10px] rounded-[10px] font-[500] text-[18px] leaing-[27px]`}
        >
          {t("Send")}
        </button>
      ) : (
        <button
          disabled
          type="submit"
          className={`bg-[#303332] text-[#219962] lg:px-[18px] px-[12px] lg:py-[11px] py-[10px] rounded-[10px] font-[500] text-[18px] leaing-[27px]`}
        >
          {t("Send")}
        </button>
      )}
    </>
  );
};

export default ButtonLayout;
