import { createSlice } from "@reduxjs/toolkit";

const LocallHostPState = createSlice({
    name: "LocallHostPState",
    initialState: ({
        language: 'ru',
        methodState: {
            page1: {
                section1: 2,
                section2: 1
            },
            page2: {
                section1: 2,
                section2: 1,
                section3: 2
            },
            page3: {
                section1: 1,
                section2: 1,
                section1: 1,
                section2: 1
            },
            page4: {
                section1: 1
            },
            page5: {
                section1: 2
            },
        },
        page: 1,
        category: 1,
    }),
    reducers: ({
        setLanguage: (state, action) => {
            state.language = action.payload
        },
        setMethodState: (state, action) => {
            state.methodState = action.payload
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setCategory: (state, action) => {
            state.category = action.payload
        },
    }),
})
export const {
    setLanguage,
    setMethodState,
    setPage,
    setCategory
} = LocallHostPState.actions;
export default LocallHostPState.reducer