import icon_add_photo from './images/ic_round-add-a-photo.png'

const cardCategory = [
    {id:1,text:'Web '},
    {id:2,text:'App '},
    {id:3,text:'Logo '},
    {id:4,text:'Brandbook '},
    {id:5,text:'Desing '},
  ]
export const method = [
    {id:1, text:'Создать'},
    {id:2, text:'Изменить'},
    {id:3, text:'Удалить'},
  ]
export const sections = {
  page1:{
    id:1, 
    section1:'Fon',
    section2: 'Flip text',
  },
  page2:{
    id:2, 
    section1:'Description',
    section2: 'Experience',
    section3: 'Image',
  },
  page3:{
    id:3, 
    text:'Resume',
    section1 : 'Experience',
    section2 : 'Education',
    section3 : 'Skills',
    section4 : 'Goals',
  },
  page4:{
    id:4, 
    section1: 'Project'
  },
  page5:{
    id:5, 
    section1: 'Contact'
  },
}
export const typeFiles = [
    {id:1, text:'Photo'},
    {id:2, text:'Video'}
  ]
export const cardCategoryDelete = [
    {id:1,text:'All '},
    {id:2,text:'Web '},
    {id:3,text:'App '},
    {id:4,text:'Logo '},
    {id:5,text:'Brandbook '},
    {id:6,text:'Desing '},
  ]

export  const uploadButton = (
    <div className='flex justify-center items-center'>
      <div>
        <img className='w-[80px] h-[80px]' src={icon_add_photo}/>
      </div>
    </div>
);

export default cardCategory