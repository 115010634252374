import { Suspense, lazy, useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { getToken } from './axiosRequest';
import Loading from './components/loading/Loading';

const Layout = lazy(()=> import('./pages/layout/Layout'))
const Main = lazy(()=> import('./pages/main/Main'))
const Login = lazy(()=> import('./pages/login/Login'))
const Admin = lazy(()=> import('./pages/admin/Admin'))


function App() {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  useEffect(()=>{
    let token = getToken()
    if(pathname.toLowerCase() == '/admin' && !token){
      navigate('/login')
    }
    if(pathname.toLowerCase() == '/login' && token){
      navigate('/admin')
    }
  },[pathname])
  return (
    <div className="">
      <Routes>
        <Route path='/' element={<Suspense fallback={<Loading/>}><Layout/></Suspense>}>
          <Route index element={<Suspense fallback={<Loading/>}><Main/></Suspense>}/>
          <Route path='/login' element={<Suspense fallback={<Loading/>}><Login/></Suspense>}/>
          <Route path='/admin' element={<Suspense fallback={<Loading/>}><Admin/></Suspense>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
