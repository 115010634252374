import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import delete_icon from "../../images/Delete.png";
import success_icon from "../../images/Group 57.png";
import close_icon from "../../images/Group 59.png";
import { Checkbox, Modal } from "antd";
import { Button } from "@mui/material";
import {
  createImage,
  createVideo,
  deleteProject,
  editExperienceText,
  editProject,
  editResume,
  setDescriptionExperience,
  setOpenFileError,
} from "../../redux/reducers/GlobalState";
import cardCategory from "../../arrays";
import getBase64 from "../../globalFunction";
import FotoVideo from "../fotoVideo/FotoVideo";
import FotoUploader from "../fotoUploader/FotoUploader";
import Language from "../language/Language";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { imgUrl } from "../../axiosRequest";

function CardModal(props) {
  const { open, close = () => {}, cardModal } = props;

  return (
    <div
      onClick={close}
      className={`${
        open ? "" : "hidden"
      } w-full fixed top-0 left-0 z-[9999] h-full overflow-y-auto`}
    >
      <div className="grid xl:grid-cols-[237px_1fr] grid-cols-1 h-full w-full">
        <div className="xl:block hidden w-full h-full"></div>
        <div className=" xl:px-[90px] md:px-[39px] px-[19px] w-full h-full flex xl:py-0 py-[50px] overflow-auto">
          <div
            onClick={(event) => event.stopPropagation()}
            className={` w-full ${
              !cardModal?.item?.item_image
                ? "bg-[#00AA5D] xl:h-full h-[622px] xl:max-h-[90vh] md:max-h-[622px] my-auto max-h-[278px] rounded-[5px] overflow-hidden"
                : "h-auto my-[10px]"
            } rounded-[5px]`}
          >
            <div className="flex flex-end relative h-0">
              <button onClick={close}>
                <img
                  src={delete_icon}
                  alt="delete_icon"
                  className="z-[9999.2] xl:w-[30px] xl:h-[30px] md:w-[23px] md:h-[22px] w-[9.28px] h-[10.11] absolute xl:top-[16px] xl:right-[13px] md:top-[14px] md:right-[14px] top-[6.07px] right-[5.57px]"
                />
              </button>
            </div>
            <div className="w-full h-full  z-[9999.1]">
              {cardModal?.item?.item_image ? (
                <div className="w-full h-auto flex mb-[50px] items-center justify-center">
                  <img
                    src={imgUrl + cardModal?.item?.item_image_second}
                    className="w-full h-full rounded-[5px]"
                  />
                </div>
              ) : (
                <div className="w-full h-full mb-[50px] items-center justify-center grid place-content-center">
                  <ReactPlayer
                    loop={true}
                    className="react-player "
                    url={imgUrl + cardModal?.item?.item_video}
                    width="100%"
                    height="100%"
                    controls={true}
                    playing={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const TelegramMessageModal = (props) => {
  const { open, close = () => {} } = props;
  const { t } = useTranslation();
  return (
    <div
      onClick={close}
      className={`${
        open ? "" : "hidden"
      } z-[99999] bg-[rgba(255,255,255,0.1)] backdrop-opacity-[12px] p-[20px] overflow-y-auto backdrop-invert fixed top-0 left-0 w-full h-screen flex justify-center`}
    >
      <div
        onClick={(event) => event.stopPropagation()}
        className="max-w-[350px] h-[350px] w-full rounded-[30px] bg-[#151617] my-auto relative flex flex-col text-center px-[24px] justify-center items-center"
      >
        <button
          onClick={close}
          className="absolute p-[9.75px] rounded-[50%] bg-[#303332] top-[-10px] right-[-10px]"
        >
          <img src={imgUrl + close_icon} alt="close_icon" className="" />
        </button>
        <div>
          <img
            src={success_icon}
            alt="success_icon"
            className="w-[82.62px] h-[82.62px]"
          />
        </div>
        <h2 className="font-[700] text-[24px] leading-[36px] text-white mt-[27.69px] mb-[7px]">
          {t("messageModal.text1")}
        </h2>
        <p className="font-[400] text-[16px] leading-[24px] text-[rgba(255,255,255,0.8)]">
          {t("messageModal.text2")}
        </p>
      </div>
    </div>
  );
};

export const DeleteModal = (props) => {
  const { deleteModal, close = () => {}, onClick } = props;
  return (
    <Modal visible={deleteModal.open} onCancel={close} footer={false}>
      <h1 className="text-[32px] fong-[700] leading-[35px] pb-[20px] text-center uppercase text-red-500 mt-[20px]">
        Delete ?
      </h1>
      <div className="w-full flex gap-[20px] mt-[20px]">
        <Button
          onClick={onClick}
          variant="contained"
          color="error"
          sx={{ width: "100%" }}
        >
          YES
        </Button>
        <Button
          onClick={close}
          variant="contained"
          color="warning"
          sx={{ width: "100%" }}
        >
          NO
        </Button>
      </div>
    </Modal>
  );
};

export const LimitFileModal = (props) => {
  const { openFileError, CloseErrorFile } = props;
  return (
    <Modal
      visible={openFileError.open}
      onCancel={CloseErrorFile}
      footer={false}
    >
      <h1 className="text-[28px] fong-[700] leading-[33px] pb-[20px] text-center uppercase text-red-500 mt-[50px]">
        Размер файла "{openFileError.name}" слишком большой!
      </h1>
    </Modal>
  );
};

export const EditProjectModal = (props) => {
  const { editProjectModal, close = () => {}, dispatch = () => {} } = props;

  const [form, setForm] = useState({
    name: editProjectModal?.item?.item_name,
    link: editProjectModal?.item?.item_link,
    type: editProjectModal?.item?.item_type,
  });
  const changeObjProject = (e) => {
    e.preventDefault();
    const body = {
      ...editProjectModal?.item,
      item_name: form.name,
      item_link: form.link,
      item_type: form.type,
    };
    dispatch(editProject(body));
  };

  useEffect(() => {
    if (editProjectModal.item) {
      setForm({
        name: editProjectModal?.item?.item_name,
        link: editProjectModal?.item?.item_link,
        type: editProjectModal?.item?.item_type,
      });
    }
  }, [editProjectModal.open]);
  return (
    <Modal
      visible={editProjectModal.open}
      onCancel={close}
      footer={false}
      className="bg-black"
      width={"70%"}
    >
      <div className="">
        <form onSubmit={changeObjProject}>
          <div className="mb-[10px]">
            <Checkbox.Group
              style={{
                width: "100%",
              }}
              value={form?.type}
              onChange={(value) => setForm({ ...form, type: value })}
              className="flex items-cencter flex-wrap gap-[30px]"
            >
              {cardCategory.map((elem, index) => {
                return (
                  <div key={elem.id} className="flex items-center gap-[10px]">
                    <Checkbox value={elem.id}></Checkbox>
                    <p className="text-[24px]">{elem.text}</p>
                  </div>
                );
              })}
            </Checkbox.Group>
          </div>
          <Language />
          <div className="grid lg:grid-cols-3 grid-cols-1 xl:gap-[42px] lg:gap-[40px] gap-[29px]">
            <input
              name="name_en"
              value={form.name?.en}
              onChange={(e) =>
                setForm({ ...form, name: { ...form.name, en: e.target.value } })
              }
              placeholder="Name en*"
              className={`transition duration-150 w-full bg-[rgba(0,0,0,0)] py-[4px] px-[10px] border-b-[1px] ${
                form.name?.en?.length > 0
                  ? "border-[#00AA5D]"
                  : "border-[#C4C4C4] focus:border-[#00AA5D]"
              } font-[300] lg:text-[16px] text-[14px] lg:leading-[24px] leading-[21px] outline-none`}
            />
            <input
              name="name_ru"
              value={form.name?.ru}
              onChange={(e) =>
                setForm({ ...form, name: { ...form.name, ru: e.target.value } })
              }
              placeholder="Name ru*"
              className={`transition duration-150 w-full bg-[rgba(0,0,0,0)] py-[4px] px-[10px] border-b-[1px] ${
                form.name?.ru?.length > 0
                  ? "border-[#00AA5D]"
                  : "border-[#C4C4C4] focus:border-[#00AA5D]"
              } font-[300] lg:text-[16px] text-[14px] lg:leading-[24px] leading-[21px] outline-none`}
            />
            <input
              name="name_tj"
              value={form.name?.tj}
              onChange={(e) =>
                setForm({ ...form, name: { ...form.name, tj: e.target.value } })
              }
              placeholder="Name tj*"
              className={`transition duration-150 w-full bg-[rgba(0,0,0,0)] py-[4px] px-[10px] border-b-[1px] ${
                form.name?.tj?.length > 0
                  ? "border-[#00AA5D]"
                  : "border-[#C4C4C4] focus:border-[#00AA5D]"
              } font-[300] lg:text-[16px] text-[14px] lg:leading-[24px] leading-[21px] outline-none`}
            />
            <input
              name="link"
              value={form.link}
              onChange={(e) => setForm({ ...form, link: e.target.value })}
              placeholder="Link*"
              className={`transition duration-150 w-full bg-[rgba(0,0,0,0)] py-[4px] px-[10px] border-b-[1px] ${
                form.link?.length > 0
                  ? "border-[#00AA5D]"
                  : "border-[#C4C4C4] focus:border-[#00AA5D]"
              } font-[300] text-[16px] leading-[24px] outline-none`}
            />
          </div>
          <Button
            type="submit"
            variant="outlined"
            color="warning"
            sx={{ margin: "20px 0px 0px 0px", width: "100%" }}
          >
            edit project
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export const EditFileModal = (props) => {
  const { editFileModal, close, dispatch = () => {} } = props;

  const [fotoOrVideo, setFotoOrVideo] = useState(1);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const handleChangeVideo = ({ file }) => {
    let reader = new FileReader();
    let url = URL.createObjectURL(file.originFileObj);
    setVideoSrc(url);
    setVideoFile(file);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChangeFoto = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const setFile = () => {
    if (
      editFileModal.type == 2 &&
      videoFile?.originFileObj?.size / 1000000 > 200
    ) {
      dispatch(
        setOpenFileError({ open: true, name: videoFile?.originFileObj?.name })
      );
      return 0;
    } else if (fotoOrVideo == 1) {
      for (let i = 0; i < fileList?.length; i++) {
        if (fileList[i]?.originFileObj?.size / 1000000 > 10) {
          dispatch(
            setOpenFileError({
              open: true,
              name: fileList[i]?.originFileObj?.name,
            })
          );
          return 0;
        }
      }
    }
    const formData = new FormData();
    if (editFileModal.type == 1) {
      for (let i = 0; i < fileList?.length; i++) {
        formData.append("images", fileList[i]?.originFileObj);
      }
      dispatch(
        createImage({
          item_id: editFileModal.id,
          formData,
          fileLink: editFileModal.fileLink,
        })
      );
    } else {
      formData.append("video", videoFile?.originFileObj);
      dispatch(
        createVideo({
          item_id: editFileModal.id,
          formData,
          fileLink: editFileModal.fileLink,
        })
      );
    }
  };

  useEffect(() => {
    if (editFileModal.open) {
      setFileList([]);
      setVideoSrc("");
      setVideoFile(null);
    }
  }, [editFileModal.open]);

  return (
    <Modal visible={editFileModal.open} onCancel={close} footer={false}>
      <div className="">
        <FotoVideo
          fotoOrVideo={editFileModal.type}
          fileList={fileList}
          handlePreview={handlePreview}
          handleChangeFoto={handleChangeFoto}
          previewOpen={previewOpen}
          previewTitle={previewTitle}
          handleCancel={handleCancel}
          previewImage={previewImage}
          handleChangeVideo={handleChangeVideo}
          videoSrc={videoSrc}
        />
        <Button
          disabled={
            editFileModal.type == 1 ? fileList?.length == 0 : !videoFile
          }
          onClick={() => setFile()}
          variant="outlined"
          color="warning"
          sx={{ margin: "20px 0px 0px 0px", width: "100%" }}
        >
          edit {editFileModal.type == 1 ? "image" : "video"}
        </Button>
      </div>
    </Modal>
  );
};

export const EditExperienceIconModal = (props) => {
  const { editExperienceIconModal, close, dispatch = () => {} } = props;

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChangeFoto = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const setFile = () => {
    for (let i = 0; i < fileList?.length; i++) {
      if (fileList[i]?.originFileObj?.size / 1000000 > 10) {
        dispatch(
          setOpenFileError({
            open: true,
            name: fileList[i]?.originFileObj?.name,
          })
        );
        return 0;
      }
    }
    const formData = new FormData();

    for (let i = 0; i < fileList?.length; i++) {
      formData.append("images", fileList[i]?.originFileObj);
    }
    dispatch(
      setDescriptionExperience({
        fileLink: editExperienceIconModal.fileLink,
        id: editExperienceIconModal.id,
        formData,
      })
    );
  };

  useEffect(() => {
    if (editExperienceIconModal.open) {
      setFileList([]);
    }
  }, [editExperienceIconModal.open]);

  return (
    <Modal
      visible={editExperienceIconModal.open}
      onCancel={close}
      footer={false}
    >
      <div className="">
        <FotoUploader
          fileList={fileList}
          previewOpen={previewOpen}
          previewTitle={previewTitle}
          handleCancel={handleCancel}
          previewImage={previewImage}
          handlePreview={handlePreview}
          handleChangeFoto={handleChangeFoto}
        />
        <Button
          disabled={fileList?.length == 0}
          onClick={setFile}
          variant="outlined"
          color="warning"
          sx={{ margin: "20px 0px 0px 0px", width: "100%" }}
        >
          edit icon
        </Button>
      </div>
    </Modal>
  );
};
export const EditExperienceModal = (props) => {
  const {
    form,
    setForm = () => {},
    close = () => {},
    dispatch = () => {},
  } = props;
  const editExperienceTextModal = useSelector(
    ({ globalState }) => globalState.editExperienceTextModal
  );

  const changeObjProject = (e) => {
    e.preventDefault();
    dispatch(
      editResume({
        body: form,
        experience_or_education: editExperienceTextModal.queryKey,
      })
    );
    close();
  };

  return (
    <Modal
      visible={editExperienceTextModal.open}
      onCancel={close}
      footer={false}
      width={"80%"}
      className="w-[90%] bg-black"
    >
      <div className="">
        <form onSubmit={changeObjProject}>
          <Language />
          <div className="grid md:grid-cols-3 grid-cols-1 xl:gap-[42px] lg:gap-[40px] gap-[29px]">
            <input
              required
              value={form?.item_title?.en}
              onChange={(e) =>
                setForm({
                  ...form,
                  item_title: { ...form?.item_title, en: e.target.value },
                })
              }
              placeholder="title en*"
              className={`transition duration-150 w-full bg-[rgba(0,0,0,0)] py-[4px] px-[10px] border-b-[1px] ${
                form?.item_title?.en?.length > 0
                  ? "border-[#00AA5D]"
                  : "border-[#C4C4C4] focus:border-[#00AA5D]"
              } font-[300] lg:text-[16px] text-[14px] lg:leading-[24px] leading-[21px] outline-none`}
            />
            <input
              required
              value={form?.item_title?.ru}
              onChange={(e) =>
                setForm({
                  ...form,
                  item_title: { ...form?.item_title, ru: e.target.value },
                })
              }
              placeholder="title ru*"
              className={`transition duration-150 w-full bg-[rgba(0,0,0,0)] py-[4px] px-[10px] border-b-[1px] ${
                form?.item_title?.ru?.length > 0
                  ? "border-[#00AA5D]"
                  : "border-[#C4C4C4] focus:border-[#00AA5D]"
              } font-[300] text-[16px] leading-[24px] outline-none`}
            />
            <input
              required
              value={form?.item_title?.tj}
              onChange={(e) =>
                setForm({
                  ...form,
                  item_title: { ...form?.item_title, tj: e.target.value },
                })
              }
              placeholder="title tj*"
              className={`transition duration-150 w-full bg-[rgba(0,0,0,0)] py-[4px] px-[10px] border-b-[1px] ${
                form?.item_title?.tj?.length > 0
                  ? "border-[#00AA5D]"
                  : "border-[#C4C4C4] focus:border-[#00AA5D]"
              } font-[300] lg:text-[16px] text-[14px] lg:leading-[24px] leading-[21px] outline-none`}
            />
            <input
              required
              value={form?.item_date?.en}
              onChange={(e) =>
                setForm({
                  ...form,
                  item_date: { ...form?.item_date, en: e.target.value },
                })
              }
              placeholder="date en*"
              className={`transition duration-150 w-full bg-[rgba(0,0,0,0)] py-[4px] px-[10px] border-b-[1px] ${
                form?.item_date?.en?.length > 0
                  ? "border-[#00AA5D]"
                  : "border-[#C4C4C4] focus:border-[#00AA5D]"
              } font-[300] lg:text-[16px] text-[14px] lg:leading-[24px] leading-[21px] outline-none`}
            />
            <input
              required
              value={form?.item_date?.ru}
              onChange={(e) =>
                setForm({
                  ...form,
                  item_date: { ...form?.item_date, ru: e.target.value },
                })
              }
              placeholder="date ru*"
              className={`transition duration-150 w-full bg-[rgba(0,0,0,0)] py-[4px] px-[10px] border-b-[1px] ${
                form?.item_date?.ru?.length > 0
                  ? "border-[#00AA5D]"
                  : "border-[#C4C4C4] focus:border-[#00AA5D]"
              } font-[300] text-[16px] leading-[24px] outline-none`}
            />
            <input
              required
              value={form?.item_date?.tj}
              onChange={(e) =>
                setForm({
                  ...form,
                  item_date: { ...form?.item_date, tj: e.target.value },
                })
              }
              placeholder="date tj*"
              className={`transition duration-150 w-full bg-[rgba(0,0,0,0)] py-[4px] px-[10px] border-b-[1px] ${
                form?.item_date?.tj?.length > 0
                  ? "border-[#00AA5D]"
                  : "border-[#C4C4C4] focus:border-[#00AA5D]"
              } font-[300] lg:text-[16px] text-[14px] lg:leading-[24px] leading-[21px] outline-none`}
            />
            <div>
              <ReactQuill
                theme="snow"
                value={form?.item_description?.en}
                onChange={(value) =>
                  setForm({
                    ...form,
                    item_description: { ...form?.item_description, en: value },
                  })
                }
              />
            </div>
            <div>
              <ReactQuill
                theme="snow"
                value={form?.item_description?.ru}
                onChange={(value) =>
                  setForm({
                    ...form,
                    item_description: { ...form?.item_description, ru: value },
                  })
                }
              />
            </div>
            <div>
              <ReactQuill
                theme="snow"
                value={form?.item_description?.tj}
                onChange={(value) =>
                  setForm({
                    ...form,
                    item_description: { ...form?.item_description, tj: value },
                  })
                }
              />
            </div>
          </div>
          <Button
            type="submit"
            variant="outlined"
            color="warning"
            sx={{ margin: "20px 0px 0px 0px", width: "100%" }}
          >
            edit project
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default CardModal;
